import { FaGithubAlt, FaUserCircle } from "react-icons/fa";
import PropTypes from "prop-types"; // Import PropTypes
// import { Navlinks } from "./Navbar";
import { Navlinks } from "./Navlinks";

const ResponsiveMenu = ({ showMenu }) => {
  console.log("showMenu", showMenu);
  return (
    <div
      className={`${showMenu ? "left-0" : "-left-[100%]"}
      fixed bottom-0 top-0 z-20 flex h-screen w-[75%] flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-16 text-black transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
    >
      <div className="card">
        <div className="flex items-center justify-start gap-3">
          <FaUserCircle size={50} />
          <div>
            <h1>Hello User</h1>
            <h1 className="text-sm text-slate-500">Premium user</h1>
          </div>
        </div>
        <nav className="mt-12">
          <ul className="space-y-4 text-xl">
            {Navlinks.map((data) => (
              <li key={data.id}> {/* Add key prop here */}
                <a href={data.link} className="mb-5 inline-block">
                  {data.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="footer">
        <h1 className="flex flex-col">
          <h1 className="flex flex-row items-center gap-1">
            Made with by <FaGithubAlt />
          </h1>
          <a href="https://github.com/dashboard" className="flex">Muluken Zeleke</a>
        </h1>
      </div>
    </div>
  );
};

ResponsiveMenu.propTypes = {
  showMenu: PropTypes.bool.isRequired, // Add prop type validation for showMenu
};

export default ResponsiveMenu;
