import  { useEffect } from "react";
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css'; // Import AOS styles

const teamMembers = [
  {
    name: "Alice Johnson",
    position: "CEO",
    bio: "Alice has over 15 years of experience in the hospitality industry, leading teams to success.",
    image: "https://example.com/alice.jpg" // Replace with actual image URL
  },
  {
    name: "Bob Smith",
    position: "CTO",
    bio: "Bob is a tech enthusiast with a passion for innovation and a background in software development.",
    image: "https://example.com/bob.jpg" // Replace with actual image URL
  },
  {
    name: "Carol Lee",
    position: "CMO",
    bio: "Carol brings a wealth of marketing experience, specializing in digital strategies and brand growth.",
    image: "https://example.com/carol.jpg" // Replace with actual image URL
  },
];

const CompanyProfile = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS for animations
  }, []);

  return (
    <div className="py-14 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto">
        {/* Company Overview */}
        <section className="mb-16">
          <h1 data-aos="fade-up" className="text-3xl sm:text-4xl font-bold text-center mb-5">
            Company Overview
          </h1>
          <p data-aos="fade-up" data-aos-delay="400" className="text-lg text-center">
            Founded in 2020, KushTech Solutions is dedicated to providing top-notch hospitality services. Our vision is to redefine luxury and comfort in the hotel industry, ensuring every guest has an unforgettable experience.
          </p>
        </section>

        {/* Team Section */}
        <section className="mb-16">
          <h2 data-aos="fade-up" className="text-2xl sm:text-3xl font-semibold text-center mb-5">
            Meet Our Team
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {teamMembers.map((member) => (
              <div
                key={member.name}
                data-aos="fade-up"
                className="border rounded-lg p-5 bg-white dark:bg-gray-800 shadow-lg"
              >
                <img src={member.image} alt={member.name} className="w-full h-48 object-cover rounded-lg mb-3" />
                <h3 className="text-xl font-bold">{member.name}</h3>
                <p className="text-gray-600">{member.position}</p>
                <p className="text-gray-700 dark:text-gray-300 mt-2">{member.bio}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Values and Mission Section */}
        <section>
          <h2 data-aos="fade-up" className="text-2xl sm:text-3xl font-semibold text-center mb-5">
            Our Values and Mission
          </h2>
          <p data-aos="fade-up" data-aos-delay="400" className="text-lg text-center">
            At KushTech Solutions, we are driven by a commitment to excellence, innovation, and sustainability. Our mission is to create memorable experiences for our guests while maintaining the highest standards of service and hospitality.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CompanyProfile;