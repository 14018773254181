import  { useEffect } from "react";
import AOS from "aos";
// import techImage from "../../assets/tech-image.png"; 

const Hero = () => {
  useEffect(() => {
    AOS.refresh();
  }, []);

  return (
    <div className="relative dark:text-white duration-300">
      {/* Background Image with Blur Effect */}
      <div
        className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1519389950473-47c0d8e4c4e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHRlY2h8ZW58MHx8fHwxNjI1NTY3MTYy&ixlib=rb-1.2.1&q=80&w=1080')] bg-cover bg-center"
      >
        <div className="absolute inset-0 bg-black opacity-40 backdrop-blur-md"></div>
      </div>

      <div className="container min-h-[620px] flex relative z-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center">
          <div
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="order-1 sm:order-2"
          >
            {/* <img
              src={techImage}
              alt="Technology Illustration"
              className="sm:scale-125 relative -z-10 max-h-[200px] drop-shadow-[2px_20px_6px_rgba(0,0,0,0.50)]"
            /> */}
          </div>
          <div className="space-y-5 order-2 sm:order-1 sm:pr-32">
            <p data-aos="fade-up" className="text-primary text-2xl font-serif">
              Innovative
            </p>
            <h1
              data-aos="fade-up"
              data-aos-delay="600"
              className="text-5xl lg:text-7xl font-semibold font-serif"
            >
              Technology Solutions
            </h1>
            <p data-aos="fade-up" data-aos-delay="1000">
              At <span className="brandColor font-medium text-2xl">KushTech Solutions,</span>  we provide cutting-edge technology services tailored to your business needs. From web development to app solutions, we ensure your success in the digital world.
            </p>
            <button
              data-aos="fade-up"
              data-aos-delay="1500"
              className="rounded-md bg-primary hover:bg-primary/80 transition duration-500 py-2 px-6 text-black"
              onClick={() => {
                window.location.href = "/contact"; // Redirect to contact page
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;