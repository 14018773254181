
import { FaCode, FaMobileAlt, FaPalette, FaCog, FaSchool, FaHospital } from "react-icons/fa";

const servicesData = [
  {
    name: "Web Development",
    icon: (
      <FaCode className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/web-development",
    description: "Custom websites tailored to your business needs with the latest technologies.",
    aosDelay: "0",
  },
  {
    name: "Mobile App Development",
    icon: (
      <FaMobileAlt className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/mobile-app-development",
    description: "Creating user-friendly apps for both iOS and Android platforms.",
    aosDelay: "500",
  },
  {
    name: "UI/UX Design",
    icon: (
      <FaPalette className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/ui-ux-design",
    description: "Designing intuitive and engaging user experiences for web and mobile.",
    aosDelay: "1000",
  },
  {
    name: "Consultancy",
    icon: (
      <FaCog className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/consultancy",
    description: "Expert advice to help you navigate your technology landscape.",
    aosDelay: "1500",
  },
  {
    name: "Maintenance",
    icon: (
      <FaCog className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/maintenance",
    description: "Ongoing support to ensure your systems run smoothly and securely.",
    aosDelay: "2000",
  },
  {
    name: "Web Apps for Schools",
    icon: (
      <FaSchool className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/web-apps-for-schools",
    description: "Custom web applications designed to enhance the educational experience.",
    aosDelay: "2500",
  },
  {
    name: "Web Apps for Companies",
    icon: (
      <FaCode className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/web-apps-for-companies",
    description: "Tailored web solutions to optimize your business processes.",
    aosDelay: "3000",
  },
  {
    name: "Web Apps for Hospitals",
    icon: (
      <FaHospital className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/web-apps-for-hospitals",
    description: "Specialized applications to improve healthcare delivery and management.",
    aosDelay: "3500",
  },
  {
    name: "Other Services",
    icon: (
      <FaCog className="text-5xl text-primary group-hover:text-black duration-300" />
    ),
    link: "/services/other-services",
    description: "Explore our range of additional services tailored to your needs.",
    aosDelay: "4000",
  },
];

const Services = () => {
  return (
    <>
      <span id="services"></span>
      <div className="dark:bg-black dark:text-white py-14 sm:min-h-[600px] sm:grid sm:place-items-center">
        <div className="container">
          <div className="pb-12">
            <h1
              data-aos="fade-up"
              className="text-3xl font-semibold text-center sm:text-4xl font-serif"
            >
              Our Services We Offer!
            </h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {servicesData.map((service) => (
              <div
                key={service.name}
                data-aos="fade-up"
                data-aos-delay={service.aosDelay}
                className="card text-center group space-y-3 sm:space-y-6 p-4 sm:py-16 bg-dark hover:bg-primary duration-300 text-white hover:text-black rounded-lg"
              >
                <div className="grid place-items-center">{service.icon}</div>
                <h1 className="text-2xl font-bold">{service.name}</h1>
                <p>{service.description}</p>
                <a
                  href={service.link}
                  className="inline-block text-lg font-semibold py-3 text-primary group-hover:text-black duration-300"
                >
                  Learn more
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;