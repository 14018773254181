import PropTypes from "prop-types"; // Import PropTypes
import About from "../About/About";
import Services from "../Services/Services";
import Hero from "../Hero/Hero";
import Testimonial from "../Testimonial/Testimonial";
import Footer from "../Footer/Footer";
import BlogResources from "../Bogs/Blogs";
import CompanyProfile from "../CompanyProfile/CompanyProfile";

const Home = ({ theme }) => {
  return (
    <div>
      <Hero theme={theme} />
      <About />
      <Services />
      <CompanyProfile />
      <Testimonial />
      <BlogResources />
      <Footer />
    </div>
  );
};

// Define prop types
Home.propTypes = {
  theme: PropTypes.string.isRequired, // Adjust type based on your expected prop type
};

export default Home;
