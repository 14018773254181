
import { FaNewspaper, FaBook, FaLightbulb } from "react-icons/fa";

const resourcesData = [
  {
    title: "Latest Industry Trends",
    icon: <FaNewspaper className="text-5xl text-primary group-hover:text-black duration-300" />,
    description: "Stay updated with the latest trends in the tech industry.",
    link: "/blog/industry-trends",
    aosDelay: "0",
  },
  {
    title: "In-Depth Guides",
    icon: <FaBook className="text-5xl text-primary group-hover:text-black duration-300" />,
    description: "Explore comprehensive guides on topics like SEO and app development.",
    link: "/blog/guides",
    aosDelay: "500",
  },
  {
    title: "Company Updates",
    icon: <FaLightbulb className="text-5xl text-primary group-hover:text-black duration-300" />,
    description: "Get the latest news and updates from KushTech Solutions.",
    link: "/blog/company-updates",
    aosDelay: "1000",
  },
];

const BlogResources = () => {
  return (
    <div className="dark:bg-black dark:text-white py-14 sm:min-h-[600px] sm:grid sm:place-items-center">
      <div className="container">
        <div className="pb-12">
          <h1
            data-aos="fade-up"
            className="text-3xl font-semibold text-center sm:text-4xl font-serif"
          >
            Blog & Resources
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {resourcesData.map((resource) => (
            <div
              key={resource.title}
              data-aos="fade-up"
              data-aos-delay={resource.aosDelay}
              className="card text-center group space-y-3 sm:space-y-6 p-4 sm:py-16 bg-dark hover:bg-primary duration-300 text-white hover:text-black rounded-lg"
            >
              <div className="grid place-items-center">{resource.icon}</div>
              <h1 className="text-2xl font-bold">{resource.title}</h1>
              <p>{resource.description}</p>
              <a
                href={resource.link}
                className="inline-block text-lg font-semibold py-3 text-primary group-hover:text-black duration-300"
              >
                Read more
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogResources;