import { useEffect } from "react";
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css'; // Import AOS styles
import Neros from '../../assets/DashBoard.png'
const projects = [
  {
    id: 1,
    title: "Project A",
    description: "An innovative solution for client X that improved efficiency by 30%.",
    image: Neros, // Use the image directly
    testimonial: "KushTech Solutions transformed our operations. Highly recommend!",
    client: "Client X",
    results: "Increased productivity and reduced costs significantly."
  },
  {
    id: 2,
    title: "Project B",
    description: "A user-friendly application for client Y that enhanced user engagement.",
    image: "https://example.com/project-b.jpg",
    testimonial: "The team delivered beyond our expectations!",
    client: "Client Y",
    results: "User engagement grew by 50% within three months."
  },
  // Add other projects...
  {
    id: 9,
    title: "Project I",
    description: "A training platform for client F that improved employee onboarding.",
    image: "https://example.com/project-i.jpg",
    testimonial: "Onboarding has never been so efficient!",
    client: "Client F",
    results: "Onboarding time cut in half."
  }
];


const Portfolio = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS for animations
  }, []);

  return (
    <div className="py-14 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto">
        <h1 className="text-3xl sm:text-4xl font-bold font-serif text-center mb-10" data-aos="fade-up">
          Our Portfolio
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {projects.map((project, index) => (
            <div
              key={project.id}
              className="rounded-lg overflow-hidden shadow-lg bg-white dark:bg-gray-800 transition-transform transform hover:scale-105"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={`${index * 200}`}
            >
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-[200px] object-cover"
              />
              <div className="p-5">
                <h2 className="text-2xl font-bold mb-3">{project.title}</h2>
                <p className="text-gray-700 dark:text-gray-300 mb-3">{project.description}</p>
                <p className="font-semibold italic mb-2">&quot;{project.testimonial}&quot;</p>
                <p className="text-gray-500">— {project.client}</p>
                <p className="text-lg font-semibold mt-2">Results:</p>
                <p className="text-gray-700 dark:text-gray-300">{project.results}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;